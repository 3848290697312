<template>
  <form class="creatorLandingPageEdit" @submit.prevent="">
    <div class="creatorLandingContent" v-if="creator">
      <div class="editLandigBlock editHeader">
        <div class="editHeaderInner">
          <UserAvatar :user="creator" size="92px" />
          <p class="creatorUserName">{{ userName }}</p>
        </div>
        <Spacer num="1" />
      </div>

      <Spacer num="1" />

      <div class="editLandigBlock">
        <div class="editTitle">
          <span>{{ $locale["principal_slider"] }}</span>
        </div>

        <div class="imagesSlider">
          <div class="imageInput">
            <ImageFileInput name="slider1" :accept="accept" :value="landing.slider1 && landing.slider1.location" />
          </div>
          <div class="imageInput">
            <ImageFileInput name="slider2" :accept="accept" :value="landing.slider2 && landing.slider2.location" />
          </div>
          <div class="imageInput">
            <ImageFileInput name="slider3" :accept="accept" :value="landing.slider3 && landing.slider3.location" />
          </div>
          <div class="imageInput">
            <ImageFileInput name="slider4" :accept="accept" :value="landing.slider4 && landing.slider4.location" />
          </div>
        </div>
      </div>

      <Spacer num="2" />

      <div class="editLandigBlock">
        <div class="editTitle">{{ $locale["image_top_creators"] }}</div>
        <div class="imageInput __top">
          <ImageFileInput name="top" :accept="accept" :value="landing.top && landing.top.location" />
        </div>
      </div>

      <Spacer num="2" />

      <div class="editLandigBlock __position">
        <div class="editTitle">{{ $locale["position_on_top_creators"] }}</div>
        <AdminCreatorLandingPosition :value="creator.priority" />
        <Spacer num="1" />
        <div class="showCreatorOnLandingPage lineToggleSwitch" flex-between padding-top padding-bottom>
          <span class="configInputLabel">{{ $locale["show_on_landing"] }}</span>
          <ToggleSwitchLight name="showOnLanding" :values="{ on: 'active', off: 'inactive' }" :value="landing.showOnLanding || 'active'" />
        </div>
      </div>

      <Spacer num="2" />

      <div class="editLandigBlock">
        <div class="editTitle">{{ $locale["image_on_stories"] }}</div>
        <div class="imageInput __story">
          <ImageFileInput name="stories" :accept="accept" :value="landing.stories && landing.stories.location" />
        </div>
      </div>

      <Spacer num="3" />

      <div class="creatorLandingConfirm">
        <Confirm @cancel="modal.close()" :confirm="confirm" @accept="updateLanding" v-if="!$loading" />
        <div class="creatorLandingConfirmLoading" v-else>
          <iconic name="gspinner" />
          <span>{{ $locale["updating"] }}</span>
          <span>{{ $store.state.progress.percent }}</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  components: {
    AdminCreatorLandingPosition: () => import("./AdminCreatorLandingPosition.vue"),
  },
  props: ["modal", "user"],
  data: function() {
    return {
      creator: null,
      accept: "image/*",
      images: [],
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    updateLanding: async function() {
      this.isLoading(true);
      try {
        const formData = new FormData(document.querySelector(".creatorLandingPageEdit"));
        const query = `?creator=${this.userName}`;
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}admin/creatorLandingPage${query}`, formData, this.axios);
        this.responseData(response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    responseData: function(data) {
      if (!data.success) {
        this.ialert.go({ title: "Error", message: data.message });
        return;
      }

      this.ialert.go({ title: this.$locale["success"], message: data.message });
    },
    loadDataCreator: async function() {
      this.isLoading(true);
      try {
        const query = `?q=${this.userName}&landing=true`;
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}creator/search${query}`);
        this.creator = response.data.creators ? response.data.creators[0] : { landing: {} };
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  mounted: function() {
    this.loadDataCreator();
  },
  computed: {
    userName: function() {
      return this.$route.params.creator || (this.user && this.user.user);
    },
    landing: function() {
      return this.creator.landing || {};
    },
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
          icon: "times",
        },
        accept: {
          label: this.$locale["update"],
          icon: "check",
        },
      };
    },
  },
};
</script>

<style lang="scss">
.creatorLandingPageEdit {
  display: block;
  padding: $mpadding * 1.5 $mpadding $mpadding $mpadding;
  .editHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 $mpadding 0;
  }
  .editHeaderInner {
    margin: auto;
    text-align: center;
  }
  .editHeaderInner .user-avatar {
    margin: auto;
  }
  .editTitle {
    font-size: 150%;
    margin: 0 0 $mpadding 0;
    border-bottom: solid 1px $alto;
    padding: 0 0 $mpadding 0;
  }
  .imageInput {
    background-color: $alto;
    height: 300px;
    cursor: pointer;
  }
  .imagesSlider {
    display: flex;
    gap: $mpadding/2;
  }
  .imageInput {
    overflow: hidden;
    margin: auto;
    max-width: 256px;
    &.__top {
      height: 256px;
      border-radius: $mpadding;
      display: flex;
    }
    &.__story {
      border-radius: $mpadding;
    }
  }
  .imagesSlider .imageInput {
    max-width: calc(25% - #{$mpadding/2});
    width: 100%;
  }
  .imageFileInput {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .creatorUserName {
    margin: $mpadding/2 0 0 0;
    font-family: Ubuntu Bold;
    font-size: 120%;
  }
  .creatorLandingConfirm {
    position: sticky;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    padding: $mpadding 0;
  }
  .creatorLandingConfirmLoading {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
  }
  .topCreatorBlock {
    display: flex;
    gap: $mpadding * 2;
  }
  .topCreatorsPosition {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: $mpadding/2;
  }
  .topSectionBlock {
    display: flex;
    gap: $mpadding * 2;
    .editTitle {
      min-width: calc(25% - #{$mpadding/2});
    }
  }

  .lineToggleSwitch {
    border-top: solid 1px $alto;
  }
}
</style>
